<template>
  <section id="edit-campaign-data">
    <b-overlay
      variant="transparent"
      :show="updatingData"
    >
      <b-card>
        <b-card-header>
          <b-card-title>{{ $t("advertising.CampaignInfo") }}</b-card-title>
        </b-card-header>
        <b-card-body>
          <validation-observer
            ref="accountRules"
            tag="form"
          >
            <b-row>
              <b-col
                lg="12"
                class="ml-2 mb-2"
              >
                <b-form @submit.prevent="validationForm">
                  <!-- Campaign name -->
                  <b-form-group
                    class="mb-2 mr-1"
                    :label="$t('advertising.CampaignName')+' *'"
                  >
                    <validation-provider
                      v-slot="validationContext"
                      name="name"
                      rules="required|min:3"
                    >
                      <b-form-input
                        v-model="campaignName"
                        :placeholder="$t('firstName')"
                        maxlength="150"
                        :state="validationContext.errors.length > 0 ? false : null"
                        autocomplete="new-password"
                        class="mr-1"
                      />
                      <small class="text-danger">{{ messageValidation(validationContext) }}</small>
                    </validation-provider>
                  </b-form-group>
                  <!-- Campaign description -->
                  <b-form-group
                    class="mb-2 mr-1"
                    :label="$t('advertising.CampaignDescription')"
                  >
                    <b-form-textarea
                      v-model="campaignDescription"
                      :placeholder="$t('advertising.CampaignDescription')"
                      autocomplete="new-password"
                      class="mr-1"
                    />
                  </b-form-group>
                  <div class="d-flex">
                    <b-form-group class="mb-2 mr-1">
                      <b-form-checkbox
                        v-model="isActive"
                        :disabled="disabled"
                        switch
                      >
                        {{ $t('isAct') }}
                      </b-form-checkbox>
                      <b-badge
                        v-if="disabled"
                        class="badge-light-warning"
                      >
                        <h6 class="text-warning mb-0">
                          {{ $t('deben') }}
                        </h6>
                      </b-badge>
                    </b-form-group>
                    <b-form-group
                      v-if="!isDisplayCampaign"
                      class="mb-2 mr-1"
                    >
                      <b-form-checkbox
                        v-model="isPriority"
                        switch
                      >
                        {{ $t('prioridad') }}
                      </b-form-checkbox>
                    </b-form-group>
                  </div>
                  <b-button
                    type="submit"
                    variant="success"
                  >
                    {{ $t("dataGeneric.save") }}
                  </b-button>
                </b-form>
              </b-col>
            </b-row>
          </validation-observer>
        </b-card-body>
      </b-card>
    </b-overlay>
  </section>
</template>
<script>
import {
  BOverlay,
  BCard,
  BCardHeader,
  BCardTitle,
  BBadge,
  BCardBody,
  BForm,
  BFormGroup,
  BFormCheckbox,
  BFormTextarea,
  BFormInput,
  BRow,
  BCol,
  BButton,
} from 'bootstrap-vue'
import axios from '@axios'
import { messageError, showToast } from '@/store/functions'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  components: {
    BOverlay,
    BCard,
    BCardHeader,
    ValidationProvider,
    ValidationObserver,
    BCardTitle,
    BFormTextarea,
    BCardBody,
    BForm,
    BFormGroup,
    BBadge,
    BFormInput,
    BRow,
    BCol,
    BFormCheckbox,
    BButton,
  },
  props: {
    info: {
      default: [],
    },
    sponsor: {
      type: Boolean,
      default: false,
    },
    isDisplayCampaign: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      required,
      campaignName: null,
      campaignDescription: null,
      updatingData: false,
      isActive: false,
      disabled: false,
      isPriority: false,
    }
  },
  watch: {
    info() {
      this.campaignName = this.info.name
      this.campaignDescription = this.info.description
      this.isActive = this.info.isActive
      this.isPriority = this.info.isPriority
      if (this.sponsor) {
        if (this.info.categories.edges.length === 0 && this.info.contents.edges.length === 0) {
          this.disabled = true
        } else {
          this.disabled = false
        }
      }
    },
  },
  mounted() {
    // this.getCampaignData();
  },
  methods: {
    messageValidation(data) {
      const existeClave = Object.keys(data.failedRules)
      if (existeClave.includes('required')) {
        return this.$t('required')
      }
      if (existeClave.includes('min')) {
        return this.$t('purchases.nameFormThreeCaract')
      }
      return ''
    },
    updateCampaignData() {
      let query = ''
      let variables = {}
      if (!this.isDisplayCampaign) {
        query = `
          mutation ($name: String, $description: String,$isActive:Boolean,$isPriority:Boolean, $id: ID!) {
            updateCampaign(id: $id, input: {
              name: $name,
              description: $description,
              isActive:$isActive,
              isPriority:$isPriority,
            }){
              campaing {
                id
                name
                description
              }
            }
          }
        `
        variables = {
          id: this.$route.params.id,
          name: this.campaignName,
          description: this.campaignDescription,
          isActive: this.isActive,
          isPriority: this.isPriority,
        }
      } else {
        query = `
          mutation ($name: String, $description: String,$isActive:Boolean, $id: ID!) {
            updateDisplayCampaign(id: $id, input: {
              name: $name,
              description: $description,
              isActive:$isActive,
            }){
              displayCampaign {
                id
                name
                description
              }
            }
          }
        `
        variables = {
          id: this.$route.params.id,
          name: this.campaignName,
          description: this.campaignDescription,
          isActive: this.isActive,
        }
      }

      axios
        .post('', { query, variables })
        .then(res => {
          messageError(res, this)

          showToast(this.$t('success'), 1, this)
        })
        .catch(() => {
          showToast(this.$t('error'), 2, this)
        })
    },
    validationForm() {
      return new Promise(resolve => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
            this.updateCampaignData()
          }
        })
      })
    },
  },
}
</script>
