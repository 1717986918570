<template>
  <section id="edit-campaign-data">
    <b-overlay
      variant="transparent"
      :show="updatingData"
    >
      <b-card>
        <b-card-header>
          <b-card-title>{{ $t("message.tableHeader.date") }}</b-card-title>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col
              lg="12"
              class="ml-2 mb-2"
            >
              <b-form @submit.prevent="validationFormInfo">
                <validation-observer
                  ref="infoRules"
                  tag="div"
                >
                  <b-form-row>
                    <b-col cols="6">
                      <!-- Campaign name -->
                      <validation-provider
                        v-slot="{ errors }"
                        name="inicioDateTime"
                        rules="required"
                      >
                        <b-form-group
                          class="mb-2 mr-1"
                          :label="$t('advertising.CampaignDateStart')+' *'"
                          :state="errors.length > 0 ? false : null"
                          :invalid-feedback="$t('required')"
                        >
                          <flat-pickr
                            v-model="inicioDateTime"
                            :state="errors.length > 0 ? false : null"
                            class="form-control"
                            :config="{
                              enableTime: true,
                              dateFormat: 'Y-m-d H:i:S'
                            }"
                          />
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col cols="6">
                      <validation-provider
                        v-slot="{ errors }"
                        name="finalDateTime"
                        rules="required"
                      >
                        <!-- Campaign description -->
                        <b-form-group
                          class="mb-2 mr-1"
                          :label="$t('advertising.CampaignDateEnd')+' *'"
                          :state="errors.length > 0 ? false : null"
                          :invalid-feedback="$t('required')"
                        >
                          <flat-pickr
                            v-model="finalDateTime"
                            class="form-control"
                            :state="errors.length > 0 ? false : null"
                            :config="{
                              enableTime: true,
                              dateFormat: 'Y-m-d H:i:S'
                            }"
                          />
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-form-row>
                  <b-button
                    type="submit"
                    variant="success"
                  >
                    {{ $t("dataGeneric.save") }}
                  </b-button>
                </validation-observer>
              </b-form>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </b-overlay>
  </section>
</template>
<script>
import {
  BOverlay,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BForm,
  BFormGroup,
  BRow,
  BCol,
  BButton,
  BFormRow,
} from 'bootstrap-vue'
import { showToast, messageError } from '@/store/functions'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'flatpickr/dist/flatpickr.css'
import axios from '@axios'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import { required } from '@validations'

export default {
  components: {
    BOverlay,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BForm,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BButton,
    BFormRow,
    flatPickr,
  },
  props: {
    info: {
      default: [],
    },
    isDisplayCampaign: {
      default: false,
      type: Boolean,
    },
  },

  data() {
    return {
      required,
      inicioDateTime: null,
      finalDateTime: null,
      updatingData: false,
    }
  },
  watch: {
    info() {
      this.inicioDateTime = this.info.startDate != null
        ? new Date(this.info.startDate)
        : null
      this.finalDateTime = this.info.finalDate != null
        ? new Date(this.info.finalDate)
        : null
    },
  },
  methods: {
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            this.updateCampaignDates()
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    updateCampaignDates() {
      const query = `
          mutation ($start: DateTime, $end: DateTime, $id: ID!) {
            ${this.isDisplayCampaign ? 'updateDisplayCampaign' : 'updateCampaign'}(id: $id, input: {
              startDate: $start,
              finalDate: $end
            }){
              ${this.isDisplayCampaign ? 'displayCampaign' : 'campaing'} {
                id
                startDate
                finalDate
              }
            }
          }
        `
      const variables = {
        id: this.$route.params.id,
        start:
          this.inicioDateTime != null ? new Date(this.inicioDateTime) : null,
        end: this.finalDateTime != null ? new Date(this.finalDateTime) : null,
      }
      axios
        .post('', { query, variables })
        .then(res => {
          messageError(res, this)

          showToast(this.$t('code.updateData'), 1, this)
        })
        .catch(() => {
          showToast(this.$t('code.updateDataError'), 0, this)
        })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
