var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"edit-campaign-data"}},[_c('b-overlay',{attrs:{"variant":"transparent","show":_vm.updatingData}},[_c('b-card',[_c('b-card-header',[_c('b-card-title',[_vm._v(_vm._s(_vm.$t("message.tableHeader.date")))])],1),_c('b-card-body',[_c('b-row',[_c('b-col',{staticClass:"ml-2 mb-2",attrs:{"lg":"12"}},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.validationFormInfo($event)}}},[_c('validation-observer',{ref:"infoRules",attrs:{"tag":"div"}},[_c('b-form-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"inicioDateTime","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mb-2 mr-1",attrs:{"label":_vm.$t('advertising.CampaignDateStart')+' *',"state":errors.length > 0 ? false : null,"invalid-feedback":_vm.$t('required')}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"state":errors.length > 0 ? false : null,"config":{
                            enableTime: true,
                            dateFormat: 'Y-m-d H:i:S'
                          }},model:{value:(_vm.inicioDateTime),callback:function ($$v) {_vm.inicioDateTime=$$v},expression:"inicioDateTime"}})],1)]}}])})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"finalDateTime","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mb-2 mr-1",attrs:{"label":_vm.$t('advertising.CampaignDateEnd')+' *',"state":errors.length > 0 ? false : null,"invalid-feedback":_vm.$t('required')}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"state":errors.length > 0 ? false : null,"config":{
                            enableTime: true,
                            dateFormat: 'Y-m-d H:i:S'
                          }},model:{value:(_vm.finalDateTime),callback:function ($$v) {_vm.finalDateTime=$$v},expression:"finalDateTime"}})],1)]}}])})],1)],1),_c('b-button',{attrs:{"type":"submit","variant":"success"}},[_vm._v(" "+_vm._s(_vm.$t("dataGeneric.save"))+" ")])],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }